<template>
  <v-form class="layout wrap align-content-start justify-center" ref="form">

    <v-flex xs12 class="mainColumn">
      <slot name="header"></slot>
    </v-flex>

    <v-flex class="mainColumn px-3">
      <v-card flat>

        <v-container fluid grid-list-md dataColumn>

          <v-layout row wrap>
            <v-flex xs6 v-if="orderParsed.order_ticket_id && !orderParsed.order_invoice_id">
              <v-list-item-title class="invoice-small-tx" >Factura simplificada</v-list-item-title>
              <v-list-item-title class="mb-1">{{ orderParsed.order_invoice_id || orderParsed.order_ticket_id }}</v-list-item-title>
            </v-flex>
            <v-flex xs6 v-if="orderParsed.order_invoice_id && orderParsed.order_ticket_id">
              <v-list-item-title class="invoice-small-tx" >Factura</v-list-item-title>
              <v-list-item-title class="mb-1">{{ orderParsed.order_invoice_id || orderParsed.order_ticket_id }}</v-list-item-title>
            </v-flex>
            <v-flex xs6>
              <v-list-item-title class="text-xs-right invoice-small-tx">Fecha</v-list-item-title>
              <v-list-item-title class="text-xs-right mb-1">{{orderParsed.closed_date}}</v-list-item-title>
            </v-flex>
          </v-layout>

          <v-divider/>

          <v-layout row wrap v-if="(orderParsed.customer_invoice_data && !isEmptyObject(orderParsed.customer_invoice_data)) || deliveryChannel">
            <v-flex xs6>
              <v-list-item-title>Cliente</v-list-item-title>
            </v-flex>
            <v-flex xs6 v-if="!(orderParsed.customer_invoice_data && !isEmptyObject(orderParsed.customer_invoice_data))">
              <v-list-item-title v-if="orderParsed.customer_name" class="text-xs-right">{{ orderParsed.customer_name }}</v-list-item-title>
            </v-flex>
            <v-flex xs6 v-else>
              <v-list-item-title class="text-xs-right">{{ orderParsed.customer_invoice_data.name }}</v-list-item-title>
              <v-list-item-title class="text-xs-right">
                {{ orderParsed.customer_invoice_data.enterprise }}.
                {{ orderParsed.customer_invoice_data.vat_id }}
              </v-list-item-title>
              <v-list-item-title class="text-xs-right">
                {{ orderParsed.customer_invoice_data.address }}.
                {{ orderParsed.customer_invoice_data.postal_code }}
                {{ orderParsed.customer_invoice_data.city }}
              </v-list-item-title>
            </v-flex>
            <v-divider/>
          </v-layout>




          <v-layout row wrap v-if="!deliveryChannel">
            <span v-if="orderParsed.table_id !== '-'">
              <v-flex xs12>
              <v-list-item-title class="mt-1 mb-1">Mesa {{ orderParsed.table_id }}</v-list-item-title>
            </v-flex>
            <v-flex xs12>
              <v-list-item-title class="text-xs-right mt-1 mb-1">Comensales: {{ orderParsed.diners }}</v-list-item-title>
            </v-flex>
            </span>
            <span v-else>
              <v-flex xs12>
              <v-list-item-title class="mt-1 mb-1">Barra</v-list-item-title>
            </v-flex>
            </span>
          </v-layout>

          <v-divider v-if="!deliveryChannel"/>

          <v-layout row wrap v-if="!deliveryChannel">
            <v-flex xs12>
              <v-list-item-title class="mt-1 mb-1">Atendido por: {{ orderParsed.worker_name }}</v-list-item-title>
            </v-flex>
          </v-layout>

          <!--          <v-layout row wrap v-if="deliveryChannel">
                      <v-flex xs6>
                        <v-list-item-title>Cliente</v-list-item-title>
                      </v-flex>
                      <v-flex xs6>
                        <v-list-item-title class="text-xs-right">{{ orderParsed.customer_name }}</v-list-item-title>
                      </v-flex>
                    </v-layout>-->

          <v-divider v-if="deliveryChannel"/>

          <span v-if="deliveryChannel && orderParsed.delivery.type === 'delivery'">
            <v-layout row wrap >
              <v-flex xs6>
                <v-list-item-title>{{ $translations.get(`common.${orderParsed.delivery.channel.toLowerCase()}`).toUpperCase() }} {{ this.orderParsed.delivery.external_id ? `· ${this.orderParsed.delivery.external_id}` : ''}}</v-list-item-title>
              </v-flex>
              <v-flex xs6>
                <v-list-item-title class="text-xs-right h-auto" v-html="deliveryAddress"></v-list-item-title>
              </v-flex>
            </v-layout>

            <v-divider />
          </span>
          <!-- ITEMS -->
          <v-flex class="scrollable_layout">
            <v-layout
                row
                wrap
                order-item
                v-for="(item, index) in orderParsed.order_items"
                :key="index"
            >
              <v-flex pb-0 pt-0 pl-0 class="item-units-flex">
                <v-list-item-title class="item-units text-xs-right">{{ item.units }}</v-list-item-title>
              </v-flex>
              <v-flex pb-0 pt-0 class="item-body">
                <v-list-item-title class="items-font-size item-main-name">{{
                    item.name }}</v-list-item-title>
              </v-flex>
              <v-flex pb-0 pt-0 class="item-price">
                <v-list-item-title v-if="item.discount !== '100%'" :class="['text-xs-right','items-font-size']">{{ item.final_price }}</v-list-item-title>
                <v-list-item-title v-if="item.discount === '100%'" :class="['text-xs-right','items-font-size']">INV.</v-list-item-title>
              </v-flex>
              <v-layout row wrap >
                <v-flex pb-0 pt-0 class="item-units-flex">
                </v-flex>
                <v-flex pt-0 pb-4 class="item-combo">
                  <!-- COMBO -->
                  <v-layout
                      row
                      wrap
                      v-for="(combo_item, combo_item_index) in item.combo_items"
                      :key="combo_item_index"
                  >
                    <v-flex pb-0 pt-0>
                      <v-list-item-title class="item-line-details text-xs-left"><span v-if="combo_item.units"> {{ combo_item.units }}x</span> {{ combo_item.name }} <span v-if="combo_item.price_mod"> ({{ combo_item.price_mod }})</span> </v-list-item-title>
                    </v-flex>
                  </v-layout>
                  <!-- OPTIONS -->
                  <v-layout
                      row
                      wrap
                      v-for="(option, options_index) in item.options"
                      :key="options_index"
                  >
                    <v-flex pb-0 pt-0>
                      <v-list-item-title class="item-line-details text-xs-left">{{ option.name }} <span v-if="option.extraprice"> ({{ option.extraprice }})</span> </v-list-item-title>
                    </v-flex>
                  </v-layout>
                  <v-list-item-title  class="item-line-details" v-if="item.product_weight > 0">{{ item.product_weight }}gr.</v-list-item-title>
                  <v-list-item-title  class="item-line-details" v-if="item.units > 1">{{ item.unit_price }}</v-list-item-title>
                  <v-list-item-title v-if="item.discount && item.discount !== ''" class="item-line-details">Descuento ({{ item.discount }})<span  v-if="item.discount_type === 'percent'" >: {{item.discount_amount }}</span></v-list-item-title>
                  <v-list-item-title v-if="orderParsed.order_taxes_groups.length > 1" class="item-line-details">IVA: {{ item.tax }}%</v-list-item-title>
                </v-flex>
                <v-flex class="item-price">
                </v-flex>
              </v-layout>
              <v-flex xs12>
                <v-divider class="item-line-divider"/>
              </v-flex>
            </v-layout>

          </v-flex>
          <v-divider/>
          <span class="order-discount" v-if="orderParsed.full_order_discount && orderParsed.full_order_discount !== ''">
            <v-list-item-title class="text-xs-right">Descuento de la orden: {{ orderParsed.full_order_discount }}</v-list-item-title>
            <v-list-item-title class="text-xs-right">Total descuento: {{ orderParsed.order_discount ? orderParsed.order_discount : 0 }}</v-list-item-title>
            <v-divider class="mt-2"/>
          </span>

          <v-layout row wrap>
            <v-flex xs12 class="order-totals">
              <v-list-item-title class="text-xs-right">Base imponible: {{ orderParsed.base_tax }}</v-list-item-title>
              <v-layout
                  v-for="(tax, tax_index) in orderParsed.order_taxes_groups"
                  :key="tax_index"
              >
                <v-list-item-title class="text-xs-right" v-if="tax.percent !== 0">Iva ({{tax.percent}}%) :{{ Number(tax.amount).toFixed(2) }}{{currencySymbol}}</v-list-item-title>
              </v-layout>
              <v-list-item-title :class="['text-xs-right','total-font-size']">Total: {{ orderParsed.order_total }}</v-list-item-title>
            </v-flex>
          </v-layout>

          <v-divider/>
          <v-layout row wrap>
            <v-flex mx-2 my-2>
              <v-list-item-title><b>PAGOS</b></v-list-item-title>
              <v-layout
                  row
                  wrap
                  v-for="(payment, payment_index) in orderParsed.payments"
                  :key="payment_index"
                  class="ml-3"
              >
                <slot v-if="payment.paid_time && payment.pay_name">
                  <v-flex xs9>
                    <v-list-item-title>{{payment.paid_time.toUpperCase() }} >> <b>{{payment.pay_name.toUpperCase()}}</b> </v-list-item-title>
                  </v-flex>
                  <v-flex xs3>
                    <v-list-item-title :class="['text-xs-right','items-font-size']">{{ payment.pay_amount }}</v-list-item-title>
                  </v-flex>
                </slot>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-divider/>
          <v-layout row wrap mt-5>
            <v-flex xs12 mt-3 class="text-xs-right ticket-footer">
              <BaseSuperButton color="info" @click="downloadPDFClick" :loading="loadingDownloadPDF"> DESCARGAR PDF <v-icon>ts-down</v-icon> </BaseSuperButton>
            </v-flex>
            <v-flex xs12 mt-1 class="text-xs-left ticket-footer">
              <BaseSuperButton @click="makeInvoiceClick" :disabled="!!orderParsed.order_invoice_id"> HACER FACTURA </BaseSuperButton>
            </v-flex>
          </v-layout>

        </v-container>

      </v-card>
    </v-flex>

    <v-flex xs12 class="mainColumn">
      <slot name="footer"></slot>
    </v-flex>

  </v-form>
</template>

<script>
import BaseSuperButton from "@/components/Base/BaseSuperButton.vue";
import {isEmptyObject} from "@/scripts/helpers/ObjectHelpers";
import axios from "axios";
import {truncateToLength} from "@/scripts/helpers/StringHelpers";

export default {
  name: 'InvoiceView',
  components: {BaseSuperButton},
  props: {
    order: Object,
    business: Object,
  },
  data() {
    return {
      decode_path:null,
      loadingDownloadPDF:false,
      currencySymbol:'€',
      orderParsed:{}
    }
  },
  mounted() {
    this.decode_path = atob(this.$route.params.token);
    this.orderParsed = this.parseOrder();
  },
  computed: {
    deliveryAddress() {
      if (!this.order || !this.deliveryChannel) {
        return ''
      }
      const deliveryData = this.deliveryChannel
      const phonePart = `<br/>Tel. ${deliveryData.customer_phone}`
      const cityPart = deliveryData.customer_city ? `${deliveryData.customer_city}, ` : ''
      const statePart = deliveryData.customer_state ? deliveryData.customer_state : ''
      const locationPart = (cityPart || statePart) ? `<br/>${cityPart}${statePart}` : ''
      const postalCodePart = deliveryData.customer_postal_code ? `<br/>${deliveryData.customer_postal_code || ''}` : ''
      return `${deliveryData.customer_address ? deliveryData.customer_address : ''}${locationPart}${postalCodePart}${phonePart}`
    },
    deliveryChannel() {
      return this.orderParsed?.delivery?.channel
    },
  },
  methods:{
    isEmptyObject,
    parseOrder(){
      //console.log('this.order: ', this.order);
      if (isEmptyObject(this.order)) {
        return {};
      }

      console.log(this.order)
      const result = {};
      result['worker_name'] = this.order.worker_name
      result['customer_name'] = this.order.customer_name
      result['customer_invoice_data'] = this.order.customer_invoice_data
      result['order_ticket_id'] =  this.order.order_ticket_id;
      result['order_invoice_id'] = this.order.order_invoice_id;
      result['closed_date'] = this.formatDate(this.order.closed_date);
      result['table_id'] = this.order.table_id;
      result['diners'] = this.order.diners;
      result['delivery'] = this.order.delivery || null
      result['order_taxes_groups'] = this.order.order_taxes_groups || []


      result['order_tax'] = Number(this.order.order_tax).toFixed(2) + this.currencySymbol;
      result['order_discount'] = Number(this.order.order_discount).toFixed(2) + this.currencySymbol;

      //PAYMENTS
      result['payments'] = []
      if (this.order?.payments) {
        this.order.payments.forEach((payment) => {
          result['payments'].push({
            pay_name: payment.pay_name && payment.pay_name !== payment.pay_type ? payment.pay_name : this.$translations.get(`components.item_list.${payment.pay_type}`),
            pay_amount: Number(payment.pay_amount).toFixed(2) + this.currencySymbol,
            paid_time: this.formatDate(payment.paid_time)
          })
        })
      }

      //FULL ORDER DISCOUNT
      if (this.order?.full_order_discount > 0) {
        result['full_order_discount'] = Number(this.order.full_order_discount).toFixed(2);
        if (this.order.full_order_discount_type === 'discount_percent') {
          result['full_order_discount'] += '%'
        } else if (this.order.full_order_discount_type === 'discount_import') {
          result['full_order_discount'] += this.currencySymbol
        }
      } else {
        result['full_order_discount'] = '';
      }
      result['base_tax'] = Number(this.order.order_total - this.order.order_tax).toFixed(2) + this.currencySymbol;
      result['order_total'] = Number(this.order.order_total).toFixed(2) + this.currencySymbol;
      result['footer'] = this.order.footer;

      //ORDER ITEMS
      result['order_items'] = [];
      this.order['order_items'].forEach((item) => {
        let temp = {};
        temp['name'] = truncateToLength(item.long_name, 40);
        temp['units'] = item.units;
        temp['tax'] = item.tax;
        temp['product_weight'] = item.product_weight || null;
        temp['unit_price'] = '(' + Number(item.final_price / item.units).toFixed(2) + this.currencySymbol + ' ' + this.$translations.get('common.unit_small') + ')';
        temp['final_price'] = Number(item.final_price).toFixed(2) + this.currencySymbol;
        //COMBO
        if (item?.elements_selected?.length > 0) {
          let combo_arr = [];
          item.elements_selected.forEach((group) => {
            if (group?.selected_items?.length > 0) {
              group.selected_items.forEach((combo_item) => {
                let combo_item_obj = {};
                combo_item_obj['units'] = combo_item.units;
                combo_item_obj['name'] = combo_item.name;
                if (combo_item.price_mod > 0) combo_item_obj['price_mod'] = combo_item.price_mod + this.currencySymbol;
                combo_arr.push(combo_item_obj);
              })
            }
          })
          temp['combo_items'] = combo_arr;
        }
        //OPTIONS
        if (item?.options?.length > 0) {
          let options = []
          item.options.forEach((option) => {
            let option_obj = {};
            option_obj['name'] = option.name;
            if (option?.options_group !== 'portions_group') {
              if (option.extraprice > 0) option_obj['extraprice'] = option.extraprice + this.currencySymbol
            }
            options.push(option_obj)
          });
          temp['options'] = options;
        }
        //DISCOUNT
        if (item.discount > 0) {
          temp['discount'] = Number(item.discount);
          temp['discount_amount'] = Number(item.discount_amount).toFixed(2) + this.currencySymbol;
          temp['discount_type'] = item.discount_type
          if (item?.discount_type === 'percent') {
            temp['discount'] += '%'
          } else if (item?.discount_type === 'import') {
            temp['discount'] += this.currencySymbol
          }
        } else {
          temp['discount'] = '';
        }
        result['order_items'].push(temp);
      });
      result['order_items_length'] = result.order_items.length;
      return result;
    },
    formatDate(date) {
      console.log(date)
      return new Date(date).toLocaleString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone:'Europe/Madrid'});
    },
    formatMoney(amount) {
      return new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(amount)
          .replace(/\./g, '')
          .replace(/,/g, '.')
          .replace(/\s/g, '');
    },
    makeInvoiceClick(){
      this.$store.commit('setCanMakeInvoice',true)
      const [group_id, local_type_id, local_id, order_id, order_time] = this.decode_path.split("/");
      this.$router.push('/invoice/'+btoa(`${group_id}/${local_type_id}/${local_id}/${order_id}/${order_time}/${this.order.order_invoice_id || this.order.order_ticket_id}`));
    },
    async downloadPDFClick() {
      this.loadingDownloadPDF = true
      const [group_id, local_type_id, local_id, order_id, order_time] = this.decode_path.split("/");
      const pdfBuffer = (await axios({
        url: `/v2/orders/get_pdf_buffer/${group_id}/${local_type_id}/${local_id}/${order_id}/${order_time}`,
        method: 'GET',
        responseType: 'blob'
      })).data
      const blob = new Blob([pdfBuffer], {type: 'application/pdf'});
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', (this.order.order_invoice_id || this.order.order_ticket_id)+'.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      this.loadingDownloadPDF = false
    }
  }
}
</script>
<style>
.items-font-size {
  font-size: 120%;
}
.scrollable_layout {
  max-height: 40%;
  overflow-y: auto;
  overflow-x: hidden;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 30px;
  padding-top: 30px;
}
.units-size {
  font-size: 30px;
  text-align: center;
}
.text-style {
  font-weight: bold;
}
</style>

