<template>
  <v-container fill-height>
    <v-row>
      <v-form class="layout wrap align-content-start justify-center" ref="form">

      <v-progress-circular v-if="loading" color="#B0C2C8" indeterminate></v-progress-circular>
        <v-flex v-if="!loading">
          <span v-if="order">
            <v-card>
              <v-card-title class="headline white--text primary"> TICKET · {{order.order_ticket_id}} {{order.order_invoice_id ? ' · '+order.order_invoice_id : ''}} </v-card-title>
              <InvoiceView
                :business="business"
                :order="order"
            />
              </v-card>
            </span>
        </v-flex>
      </v-form>
    </v-row>
  </v-container>
</template>

<script>

import InvoiceView from "@/components/InvoiceView.vue";
import BaseSuperButton from "@/components/Base/BaseSuperButton.vue";
import MakeInvoiceView from "@/components/MakeInvoiceView.vue";

export default {
  components: {MakeInvoiceView, BaseSuperButton, InvoiceView},
  data() {
    return {
      order:null,
      business:null,
      loading:true,
      decode_path:null
    }
  },
  mounted() {
    this.decode_path = atob(this.$route.params.token);
    this.loadData();
  },
  methods: {
    async loadData() {
      const regexp = /^([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)\/([^/]+)$/;
      if (regexp.test(this.decode_path)) {
        const [group_id, local_type_id ,local_id, order_id, order_time] = this.decode_path.split("/");
        this.order = (await this.$http.get(`/v2/orders/get_order_by_path/${group_id}/${local_id}/${order_id}/${order_time}`)).data
        this.business = (await this.$http.get(`/v2/business/${group_id}/${local_type_id}/${local_id}`)).data
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
/* Add any specific styles here if needed */
</style>
